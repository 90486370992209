<div class="grid">
  <div
    class="col-12"
    style="display: flex; align-items: center; justify-content: space-between"
  >
    <label class="title">Consolidated Proxy Voting Result</label>
    <button
      pButton
      pRipple
      label="Generate Report"
      class="p-button-raised p-button-primary mr-2 mb-2"
      (click)="generateProxyConsolidatedReport()"
    ></button>
  </div>
  <div class="col-12">
    <p-table
      #dt1
      [value]="votingResult"
      dataKey="id"
      [rows]="10"
      [loading]="votingResultLoading"
      [rowHover]="true"
      styleClass="p-datatable-gridlines"
      [paginator]="true"
      responsiveLayout="scroll"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>
            <div class="flex justify-content-between align-items-center">
              No
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Resolution Type
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Resolution Description
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Level
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              For % (Units)
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Against % (Units)
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Abstain Units
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Result
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-result>
        <tr>
          <td style="min-width: 5rem">
            {{ result.index }}
          </td>
          <td style="min-width: 10rem">
            {{ result.type }}
          </td>

          <td style="min-width: 20rem">
            {{ result.title }}
          </td>
          <td style="min-width: 10rem">
            <ng-container *ngIf="result.type === 'TWO_TIER'">
              <div class="large-tier">Large</div>
              <div>Other</div>
            </ng-container>
          </td>
          <td style="min-width: 10rem">
            <ng-container *ngIf="result.type === 'TWO_TIER'">
              <div class="large-tier">
                {{ result.for.largePercentage | number: "1.1-4" }}% ({{
                  result.for.largeUnit | number: "1.0-0"
                }})
              </div>
              <div>
                {{ result.for.smallPercentage | number: "1.1-4" }}% ({{
                  result.for.smallUnit | number: "1.0-0"
                }})
              </div>
            </ng-container>
            <ng-container *ngIf="result.type !== 'TWO_TIER'">
              {{ result.for.percentage | number: "1.1-4" }}% ({{
                result.for.unit | number: "1.0-0"
              }})
            </ng-container>
          </td>
          <td style="min-width: 10rem">
            <ng-container *ngIf="result.type === 'TWO_TIER'">
              <div class="large-tier">
                {{ result.against.largePercentage | number: "1.1-4" }}% ({{
                  result.against.largeUnit | number: "1.0-0"
                }})
              </div>
              <div>
                {{ result.against.smallPercentage | number: "1.1-4" }}% ({{
                  result.against.smallUnit | number: "1.0-0"
                }})
              </div>
            </ng-container>
            <ng-container *ngIf="result.type !== 'TWO_TIER'">
              {{ result.against.percentage | number: "1.1-4" }}% ({{
                result.against.unit | number: "1.0-0"
              }})
            </ng-container>
          </td>
          <td style="min-width: 10rem">
            <ng-container *ngIf="result.type === 'TWO_TIER'">
              <div class="large-tier">
                {{ result.abstain.largeUnit | number: "1.0-0" }}
              </div>
              <div>
                {{ result.abstain.smallUnit | number: "1.0-0" }}
              </div>
            </ng-container>
            <ng-container *ngIf="result.type !== 'TWO_TIER'">
              {{ result.abstain.unit | number: "1.0-0" }}
            </ng-container>
          </td>
          <td style="min-width: 10rem">
            {{ result.result }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No resolutions found.</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
          <td colspan="7">Loading event resolutions. Please wait.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<div class="grid">
  <div
    class="col-12"
    style="display: flex; align-items: center; justify-content: space-between"
  >
    <label class="title">Proxy Votings</label>
  </div>

  <div class="col-12">
    <p-table
      #dt1
      [value]="votings"
      dataKey="id"
      [rows]="10"
      [loading]="votingLoading"
      [rowHover]="true"
      styleClass="p-datatable-gridlines"
      [paginator]="true"
      [globalFilterFields]="['title', 'type']"
      responsiveLayout="scroll"
    >
      <ng-template pTemplate="caption">
        <div class="flex justify-content-between flex-column sm:flex-row">
          <span class="p-input-icon-left mb-2">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              #filter
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              placeholder="Search Keyword"
              class="w-full"
            />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>
            <div class="flex justify-content-between align-items-center">
              Proxy Name
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Proxy NRIC
            </div>
          </th>

          <th>
            <div class="flex justify-content-between align-items-center">
              Shareholder NRIC
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Shareholder CDS
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Number of Shares
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Is Prevote
            </div>
          </th>
          <ng-container *ngFor="let resolution of resolutions">
            <th>
              <div class="flex justify-content-between align-items-center">
                Resolution {{ resolution.index }}
              </div>
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-voting>
        <tr>
          <td style="min-width: 12rem">
            {{ voting.proxyId.name }}
          </td>
          <td style="min-width: 12rem">
            {{ voting.proxyId.identityNumber }}
          </td>

          <td style="min-width: 14rem">
            {{ voting.shareholderId.identityNumber }}
          </td>
          <td style="min-width: 10rem">
            {{ voting.shareholderId.cds }}
          </td>
          <td style="min-width: 10rem">
            {{ voting.result[0].numberOfShares | number: "1.0-0" }}
          </td>
          <td style="min-width: 10rem">
            {{ voting.isPreVote }}
          </td>
          <ng-container *ngFor="let resolution of resolutions">
            <td style="min-width: 10rem">
              {{ getResolutionVoting(resolution, voting.result) }}
            </td>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No proxy votings found.</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
          <td colspan="7">Loading event proxy votings. Please wait.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
