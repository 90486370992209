<p-table
  #dt1
  [value]="votingResult"
  dataKey="id"
  [rows]="10"
  [rowHover]="true"
  styleClass="p-datatable-gridlines"
  [paginator]="true"
  responsiveLayout="scroll"
>
  <ng-template pTemplate="header">
    <tr>
      <th>
        <div class="flex justify-content-between align-items-center">No</div>
      </th>
      <th>
        <div class="flex justify-content-between align-items-center">
          Resolution Type
        </div>
      </th>
      <th>
        <div class="flex justify-content-between align-items-center">
          Resolution Description
        </div>
      </th>
      <th>
        <div class="flex justify-content-between align-items-center">Level</div>
      </th>
      <th>
        <div class="flex justify-content-between align-items-center">
          For % (Units)
        </div>
      </th>
      <th>
        <div class="flex justify-content-between align-items-center">
          Against % (Units)
        </div>
      </th>
      <th>
        <div class="flex justify-content-between align-items-center">
          Abstain Units
        </div>
      </th>
      <th>
        <div class="flex justify-content-between align-items-center">
          Result
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-result>
    <tr>
      <td style="min-width: 5rem">{{ result.index }}</td>
      <td style="min-width: 10rem">{{ result.type }}</td>

      <td style="min-width: 20rem">{{ result.title }}</td>
      <td style="min-width: 10rem">
        <ng-container *ngIf="result.type === 'TWO_TIER'">
          <div class="large-tier">Large</div>
          <div>Other</div>
        </ng-container>
      </td>
      <td style="min-width: 10rem">
        <ng-container *ngIf="result.type === 'TWO_TIER'">
          <div class="large-tier">
            {{ result.for.largePercentage | number: "1.1-4" }}% ({{
            result.for.largeUnit | number: "1.0-0" }})
          </div>
          <div>
            {{ result.for.smallPercentage | number: "1.1-4" }}% ({{
            result.for.smallUnit | number: "1.0-0" }})
          </div>
        </ng-container>
        <ng-container *ngIf="result.type !== 'TWO_TIER'">
          {{ result.for.percentage | number: "1.1-4" }}% ({{ result.for.unit |
          number: "1.0-0" }})
        </ng-container>
      </td>
      <td style="min-width: 10rem">
        <ng-container *ngIf="result.type === 'TWO_TIER'">
          <div class="large-tier">
            {{ result.against.largePercentage | number: "1.1-4" }}% ({{
            result.against.largeUnit | number: "1.0-0" }})
          </div>
          <div>
            {{ result.against.smallPercentage | number: "1.1-4" }}% ({{
            result.against.smallUnit | number: "1.0-0" }})
          </div>
        </ng-container>
        <ng-container *ngIf="result.type !== 'TWO_TIER'">
          {{ result.against.percentage | number: "1.1-4" }}% ({{
          result.against.unit | number: "1.0-0" }})
        </ng-container>
      </td>
      <td style="min-width: 10rem">
        <ng-container *ngIf="result.type === 'TWO_TIER'">
          <div class="large-tier">
            {{ result.abstain.largeUnit | number: "1.0-0" }}
          </div>
          <div>{{ result.abstain.smallUnit | number: "1.0-0" }}</div>
        </ng-container>
        <ng-container *ngIf="result.type !== 'TWO_TIER'">
          {{ result.abstain.unit | number: "1.0-0" }}
        </ng-container>
      </td>
      <td style="min-width: 10rem">{{ result.result }}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No resolutions found.</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="loadingbody">
    <tr>
      <td colspan="7">Loading event resolutions. Please wait.</td>
    </tr>
  </ng-template>
</p-table>
