<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5>Users</h5>

      <p-table
        #dt1
        [value]="users"
        dataKey="id"
        [rows]="10"
        [loading]="loading"
        [rowHover]="true"
        styleClass="p-datatable-gridlines"
        [paginator]="true"
        [globalFilterFields]="['mobile','name', 'status','nric']"
        responsiveLayout="scroll"
      >
        <ng-template pTemplate="caption">
          <div class="flex justify-content-between flex-column sm:flex-row">
            <!-- <button
              pButton
              pRipple
              label="Create"
              class="mr-2 mb-2"
              (click)="createUser()"
            ></button> -->

            <span class="p-input-icon-left mb-2">
              <i class="pi pi-search"></i>
              <input
                pInputText
                type="text"
                #filter
                (input)="dt1.filterGlobal($event.target.value, 'contains')"
                placeholder="Search Keyword"
                class="w-full"
              />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>
              <div class="flex justify-content-between align-items-center">
                Mobile(Unique Identifier)
              </div>
            </th>

            <th>
              <div class="flex justify-content-between align-items-center">
                Name
              </div>
            </th>

            <th>
              <div class="flex justify-content-between align-items-center">
                NRIC
              </div>
            </th>
            <th>
              <div class="flex justify-content-between align-items-center">
                Created Date
              </div>
            </th>
            <th>
              <div class="flex justify-content-between align-items-center">
                Status
              </div>
            </th>
            <th>
              <div class="flex justify-content-between align-items-center">
                Account Verification Status
              </div>
            </th>
            <th>
              <div class="flex justify-content-between align-items-center">
                Actions
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
          <tr>
            <td style="min-width: 12rem">
              {{ user.mobile }}
            </td>

            <td style="min-width: 12rem">
              {{ user.name }}
            </td>

            <td style="min-width: 14rem">
              {{ user.nric }}
            </td>
            <td style="min-width: 10rem">
              {{ user.createdAt | date: "MM/dd/yyyy" }}
            </td>

            <td style="min-width: 12rem">
              <span [class]="'status-badge status-' + user.status">{{
                user.status
              }}</span>
            </td>
            <td style="min-width: 14rem">
              {{ user.accountVerificationStatus | uppercase }}
            </td>
            <td style="min-width: 10rem">
              <button
                pButton
                pRipple
                label="View"
                class="mr-2 mb-2"
                (click)="viewUser(user)"
              ></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">No users found.</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody">
          <tr>
            <td colspan="7">Loading users data. Please wait.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
