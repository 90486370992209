export enum MimeTypeEnum {
  Jpeg = "image/jpeg",
  Jpg = "image/jpg",
  Png = "image/png",
  Gif = "image/gif",
  Pdf = "application/pdf",
  Csv = "text/csv",
  Doc = "application/msword",
  Docx = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  Ppt = "application/vnd.ms-powerpoint",
  Pptx = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  Xls = "application/vnd.ms-excel",
  Xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  Txt = "text/plain",
  Zip = "application/zip",
}

export enum FileFormatsEnum {
  csv = "csv",
  xls = "xls",
  xlsx = "xlsx",
  txt = "txt",
  pdf = "pdf",
  doc = "doc",
  docx = "docx",
  jpg = "jpg",
  jpeg = "jpeg",
  png = "png",
}
