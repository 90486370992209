<div class="col-12" *ngIf="!loading">
  <div class="card">
    <h4>Company Info</h4>

    <div class="p-fluid p-formgrid grid" [formGroup]="companyInfo">
      <div class="field col-12">
        <label htmlFor="companyName">Company Name</label>
        <input
          pInputText
          id="companyName"
          [ngModel]="company.name"
          [ngModelOptions]="{ standalone: true }"
          disabled="true"
          type="text"
        />
      </div>

      <div class="field col-12 md:col-6">
        <label htmlFor="companyRegId">Company Registration No.</label>
        <input
          pInputText
          id="companyRegId"
          type="text"
          formControlName="registrationNumber"
        />
      </div>
      <div class="field col-12 md:col-6">
        <label htmlFor="contactNo">Contact No.</label>
        <input pInputText id="contactNo" type="text" formControlName="phone" />
      </div>

      <div class="field col-12" formGroupName="address">
        <h5>Address</h5>
        <div class="p-fluid p-formgrid grid">
          <div class="field col-12 md:col-6">
            <label htmlFor="addressLine1">Line 1</label>
            <input
              pInputText
              id="addressLine1"
              type="text"
              formControlName="line1"
            />
          </div>

          <div class="field col-12 md:col-6">
            <label htmlFor="addressLine2">Line 2</label>
            <input
              pInputText
              id="addressLine2"
              type="text"
              formControlName="line2"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label htmlFor="city">City</label>
            <input pInputText id="city" type="text" formControlName="city" />
          </div>
          <div class="field col-12 md:col-3">
            <label htmlFor="state">State</label>
            <input
              pInputText
              id="state"
              type="text"
              formControlName="countryZone"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label htmlFor="zip">Postal Code</label>
            <input pInputText id="zip" type="text" formControlName="zip" />
          </div>
          <div class="field col-12 md:col-3">
            <label htmlFor="country">Country</label>
            <input
              pInputText
              id="country"
              type="text"
              formControlName="country"
            />
          </div>
        </div>
      </div>

      <div class="field col-12 md:col-6">
        <h5>Company Logo</h5>
        <div style="width: fit-content">
          <app-photo
            [photoUrl]="photoUrl"
            (photoUploaded)="addPhoto($event)"
            (photoDeleted)="removePhoto($event)"
          ></app-photo>
        </div>
      </div>
    </div>

    <button
      pButton
      pRipple
      label="Save"
      class="mr-2 mb-2"
      (click)="updateCompanyInfo()"
    ></button>
  </div>
</div>
