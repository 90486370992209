<ng-container>
  <p-toast></p-toast>
  <p-message></p-message>
  <div class="action-container">
    <div class="action">
      <button
        pButton
        pRipple
        class="p-button-outlined p-button-secondary mr-2"
        label="Ask Question"
        (click)="askQuestion()"
      ></button>
      <button
        pButton
        pRipple
        class="p-button-primary mr-2"
        label="Leave"
        (click)="leave()"
      ></button>
    </div>
  </div>
  <div class="grid hidden lg:flex">
    <div class="event-title">
      <img
        src="assets/layout/images/vpoll.png"
        alt="logo"
        style="height: 105px"
      />
      <div class="title" *ngIf="userEvent">
        {{ userEvent.company.name }}: {{ userEvent.event.name }}
      </div>
    </div>
  </div>
  <div class="grid lg:hidden flex">
    <div class="mt-4 mb-2" *ngIf="userEvent">
      {{ userEvent.company.name }}: {{ userEvent.event.name }}
    </div>
  </div>
  <div class="grid">
    <div class="lg:col-8 col-12">
      <div class="grid">
        <div id="meetingSDKElement"></div>
        <!-- <button (click)="getSignature()" class="modern-button" [disabled]="isMeetingActive">Join Meeting</button> -->
        
        <!-- <iframe width="600" height="415" src="https://www.youtube.com/embed/xwDss1HgEfs?si=-XP_8leJNTXZoA5S" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
      </div>
      <div class="grid pt-4">
        <div class="col-12 lg:pl-4">
          <div class="card">
            <h5>Event Information</h5>
            
            <div class="flex">
              <div
                class="flex cursor-pointer"
                (click)="goToLink(userEvent.event.noticeOfAgmUrl)"
              >
                <i class="pi pi-file-pdf" style="font-size: 2rem; color: red">
                </i>
                <span style="margin: auto 8px">Notice of AGM</span>
              </div>


              <div
                class="flex ml-4 cursor-pointer"
                (click)="goToLink(userEvent.event.annualReportUrl)"
              >
                <i class="pi pi-file-pdf" style="font-size: 2rem; color: red">
                </i>
                <span style="margin: auto 8px">Annual Report</span>
              </div>

              <div class="join-buttons-container">
                <button (click)="getSignature()" class="modern-button" *ngIf="!isMeetingActive">Join Meeting</button>
                <button class="modern-button-zoom" (click)="joinZoomMeeting()">Join via Zoom</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lg:col-4 col-12">
      <div class="personal-info card lg:mr-4" *ngIf="userEvent">
        <ul style="list-style-type: none; padding: 0px; margin: 0px">
          <li *ngIf="userEvent.chairmanIdentity.isChairman">
            <h5>Chairman Information</h5>

            <div *ngIf="userEvent.chairmanIdentity.proxies.length">
              <h6>Shareholders to vote on behalf as Proxy</h6>
              <p-table
                [value]="userEvent.chairmanIdentity.proxies"
                responsiveLayout="scroll"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th>Shareholder</th>
                    <th>CDS</th>
                    <th>Action</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-proxy>
                  <tr>
                    <td>{{ proxy.shareholderId.name }}</td>
                    <td>{{ proxy.shareholderId.cds }}</td>
                    <td>
                      <button
                      *ngIf="!proxy.voteSetting.isPreVote"
                        pButton
                        pRipple
                        class="p-button-outlined p-button-primary mr-2"
                        label="Vote"
                        (click)="voteFor(proxy)"
                      ></button>
                      <div *ngIf="proxy.voteSetting.isPreVote">Pre-Voted</div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

            <div *ngIf="userEvent.chairmanIdentity.secondProxies.length">
              <h6>Shareholders to vote on behalf as 2nd Proxy</h6>
              <p-table
                [value]="userEvent.chairmanIdentity.secondProxies"
                responsiveLayout="scroll"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th>Shareholder</th>
                    <th>CDS</th>
                    <th>Action</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-proxy>
                  <tr>
                    <td>{{ proxy.shareholderId.name }}</td>
                    <td>{{ proxy.shareholderId.cds }}</td>
                    <td>
                      <button
                      *ngIf="!proxy.voteSetting.isPreVote"
                        pButton
                        pRipple
                        class="p-button-outlined p-button-primary mr-2"
                        label="Vote"
                        (click)="voteAs2ndProxy(proxy)"
                      ></button>
                      <div *ngIf="proxy.voteSetting.isPreVote">Pre-Voted</div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

            <div *ngIf="!userEvent.chairmanIdentity.hasProxies">
              You are not assigned as proxy for shareholders
            </div>
          </li>
          <li *ngIf="userEvent.shareholderIdentity.isShareholder">
            <h4>Shareholding Information</h4>
            <p-table
              [value]="userEvent.shareholderIdentity.shareholders"
              responsiveLayout="scroll"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th>CDS</th>
                  <th>No of Shares</th>
                  <th>Remainder Shares</th>
                  <th>Action</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-shareholder>
                <tr>
                  <td>{{ shareholder.cds }}</td>
                  <td>{{ shareholder.numberOfShares | number: "1.0-0" }}</td>
                  <td>{{ shareholder.remainderShares | number: "1.0-0" }}</td>
                  <td>
                    <button
                      pButton
                      pRipple
                      class="p-button-outlined p-button-primary mr-2"
                      label="Vote"
                      (click)="vote(shareholder)"
                    ></button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <ng-container *ngIf="userEvent.shareholderIdentity.proxies.length">
              <h6>Proxy Assigned</h6>
              <p-table
                [value]="userEvent.shareholderIdentity.proxies"
                responsiveLayout="scroll"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th>NRIC</th>
                    <th>Allocated Shares</th>
                    <th>Action</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-proxy>
                  <tr>
                    <td>{{ proxy.identityNumber }}</td>
                    <td>{{ proxy.allocatedShares | number: "1.0-0" }}</td>
                    <td>
                      <button
                        pButton
                        pRipple
                        class="p-button-outlined p-button-primary mr-2"
                        label="View"
                        (click)="viewProxyVote(proxy)"
                      ></button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </ng-container>
          </li>
          <li *ngIf="userEvent.proxyIdentity.isProxy">
            <h5>Proxy Information</h5>
            <p-table
              [value]="userEvent.proxyIdentity.proxies"
              responsiveLayout="scroll"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th>Shareholder</th>
                  <th>CDS</th>
                  <th>Action</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-proxy>
                <tr>
                  <td>{{ proxy.shareholderId.name }}</td>
                  <td>{{ proxy.shareholderId.cds }}</td>
                  <td>
                    <button
                    *ngIf="!proxy.voteSetting.isPreVote"
                      pButton
                      pRipple
                      class="p-button-outlined p-button-primary mr-2"
                      label="Vote"
                      (click)="voteFor(proxy)"
                    ></button>
                    <div *ngIf="proxy.voteSetting.isPreVote">Pre-Voted</div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- <div id="hidewin" class="grid">
    <div class="col-12">
      <div id="meetingSDKElement"></div>
    </div>
 
  </div> -->
</ng-container>