<div class="grid">
  <div class="col-6">
    <label class="title">Audit Logs</label>
  </div>
  <div class="col-6" style="display: flex; justify-content: end"></div>

  <div class="col-12">
    <p-table
      #dt1
      [value]="auditLogs"
      dataKey="id"
      [rows]="10"
      [rowHover]="true"
      styleClass="p-datatable-gridlines"
      [paginator]="true"
      [globalFilterFields]="['data.description']"
      responsiveLayout="scroll"
    >
      <ng-template pTemplate="caption">
        <div class="flex justify-content-between flex-column sm:flex-row">
          <span class="p-input-icon-left mb-2">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              #filter
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              placeholder="Search Keyword"
              class="w-full"
            />
          </span>
          <p-columnFilter
            field="data.type"
            matchMode="equals"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <p-dropdown
                [ngModel]="selectedType"
                [options]="types"
                (onChange)="filter($event.value)"
                placeholder="Any"
                [showClear]="true"
              >
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pFrozenColumn>
            <div class="flex justify-content-between align-items-center">
              Category
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Description
            </div>
          </th>
          <th pFrozenColumn alignFrozen="right">
            <div class="flex justify-content-between align-items-center">
              Date/Time
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-audit>
        <tr>
          <td style="min-width: 6rem" pFrozenColumn>
            {{ audit.data.type }}
          </td>
          <td style="min-width: 20rem">{{ audit.data.description }}</td>
          <td style="min-width: 6rem" pFrozenColumn alignFrozen="right">
            {{ audit.createdAt | date: "medium" }}
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No invitees.</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
          <td colspan="7">Loading invitees list. Please wait.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
