export enum ImportExportFileWriterEnum {
  CSV = "csv",
  XLS = "xls",
  XLSX = "xlsx"
}

export enum ImportFileEnum {
  SHAREHOLDER = "shareholder"
}

export enum ImportFileErrorCodes {
  FILE_FORMAT_NOT_SUPPORTED = "GENERIC_FILE.FILE_FORMAT_NOT_SUPPORTED",
  FILE_IMPORT_ERROR = "GENERIC_FILE.FILE_IMPORT_ERROR",
  HEADER_FORMAT_INVALID = "GENERIC_FILE.HEADER_FORMAT_INVALID",
  DUPLICATE_UNIQUE_IDENTITY = "GENERIC_FILE.DUPLICATE_UNIQUE_IDENTITY",
  DATE_FORMAT_INVALID = "GENERIC_FILE.DATE_FORMAT_INVALID",
  NOT_APPLICABLE = "GENERIC_FILE.NOT_APPLICABLE",
  NOT_A_NUMBER = "GENERIC_FILE.NOT_A_NUMBER",
  SELECT_OPTION_INVALID = "GENERIC_FILE.SELECT_OPTION_INVALID",
  INVALID_EMAIL = "GENERIC_FILE.INVALID_EMAIL",
  CLASS_VALIDATION_FAIL = "GENERIC_FILE.CLASS_VALIDATION_FAIL",
  BROKER_ERROR = "GENERIC_FILE.BROKER_ERROR"
}
