<p-table
  #dt1
  [value]="shareholderAbstainList"
  dataKey="id"
  [rows]="10"
  [rowHover]="true"
  styleClass="p-datatable-gridlines"
  [paginator]="true"
  [globalFilterFields]="['name', 'status']"
  responsiveLayout="scroll"
>
  <ng-template pTemplate="caption">
    <div class="flex justify-content-between flex-column sm:flex-row">
      <span class="p-input-icon-left mb-2">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          #filter
          (input)="dt1.filterGlobal($event.target.value, 'contains')"
          placeholder="Search Keyword"
          class="w-full"
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>
        <div class="flex justify-content-between align-items-center">CDS</div>
      </th>

      <th>
        <div class="flex justify-content-between align-items-center">
          Shareholder Name
        </div>
      </th>
      <th>
        <div class="flex justify-content-between align-items-center">NRIC</div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-shareholder>
    <tr>
      <td style="min-width: 12rem">
        {{ shareholder.cds }}
      </td>

      <td style="min-width: 14rem">
        {{ shareholder.name }}
      </td>

      <td style="min-width: 14rem">
        {{ shareholder.identityNumber }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No abstain CDS.</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="loadingbody">
    <tr>
      <td colspan="7">Loading abstain CDS list. Please wait.</td>
    </tr>
  </ng-template>
</p-table>