<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5>Events</h5>

      <p-table
        #dt1
        [value]="events"
        dataKey="id"
        [rows]="10"
        [loading]="loading"
        [rowHover]="true"
        styleClass="p-datatable-gridlines"
        [paginator]="true"
        [globalFilterFields]="['name']"
        responsiveLayout="scroll"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>
              <div class="flex justify-content-between align-items-center">
                Event
              </div>
            </th>

            <th>
              <div class="flex justify-content-between align-items-center">
                Start Time
              </div>
            </th>
            <th>
              <div class="flex justify-content-between align-items-center">
                End Time
              </div>
            </th>
            <th>
              <div class="flex justify-content-between align-items-center">
                Role
              </div>
            </th>
            <th>
              <div class="flex justify-content-between align-items-center">
                Actions
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-event>
          <tr>
            <td style="min-width: 12rem">
              {{ event.name }}
            </td>

            <td style="min-width: 14rem">
              {{ event.startAt | date: "short" }}
            </td>
            <td style="min-width: 10rem">
              {{ event.endAt | date: "short" }}
            </td>
            <td style="min-width: 10rem">
              {{ getEventRoles(event._id) }}
            </td>

            <td style="min-width: 10rem">
              <button
                *ngIf="!isEventEnded(event)"
                [disabled]="!isEventStarted(event)"
                pButton
                pRipple
                label="Join"
                class="p-button-raised p-button-primary mr-2 mb-2"
                (click)="joinEvent(event)"
              ></button>
              <button
                *ngIf="isResultPublished(event)"
                pButton
                pRipple
                label="View Result"
                class="p-button-raised p-button-primary mr-2 mb-2"
                (click)="viewResult(event)"
              ></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">No events found.</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody">
          <tr>
            <td colspan="7">Loading events data. Please wait.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
