<div class="p-fluid" [formGroup]="form">
  <div class="field">
    <label htmlFor="email1">Company Admin Name</label>
    <input pInputText id="email1" type="text" formControlName="name" />
  </div>
  <div class="field">
    <label htmlFor="age1">Company Admin Email</label>
    <input pInputText id="age1" type="text" formControlName="email" />
  </div>
</div>

<button
  *ngIf="operation === ModalOperationEnum.CREATE"
  pButton
  pRipple
  label="Create"
  class="mr-2 mb-2"
  (click)="create()"
></button>

<!-- <button
*ngIf="operation === ModalOperationEnum.UPDATE"
  pButton
  pRipple
  label="Create"
  class="mr-2 mb-2"
  (click)="update()"
></button> -->
