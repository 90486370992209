<div class="layout-topbar">
  <a class="layout-topbar-logo" routerLink="">
    <img
      src="assets/layout/images/vpoll.png"
      alt="logo"
      style="height: 105px"
    />
  </a>

  <a
    class="p-link layout-menu-button layout-topbar-button"
    href="#"
    (click)="appMain.toggleMenu($event)"
  >
    <i class="pi pi-bars"></i>
  </a>

  <a
    class="p-link layout-topbar-menu-button layout-topbar-button"
    href="#"
    (click)="appMain.toggleTopMenu($event)"
  >
    <i class="pi pi-ellipsis-v"></i>
  </a>

  <div
    class="layout-topbar-menu"
    [ngClass]="{ 'layout-topbar-menu-mobile-active': appMain.topMenuActive }"
  >
    <button
      pButton
      pRipple
      label="Sign Out"
      icon="pi pi-sign-out"
      iconPos="right"
      class="mr-2 mb-2"
      (click)="logout()"
    ></button>
  </div>
</div>
