<div
  class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
>
  <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 80%">
    <div class="col-12 xl:col-6" style="border-radius: 56px; padding: 0.3rem">
      <div
        class="h-full w-full m-0 py-7 px-4"
        style="
          border-radius: 53px;
          background: linear-gradient(
            180deg,
            var(--surface-50) 38.9%,
            var(--surface-0)
          );
        "
      >
        <div class="text-center mb-5">
          <img
            src="assets/layout/images/vpoll.png"
            alt="Vpoll logo"
            class="mb-5 cursor-pointer"
            style="width: 200px"
            (click)="router.navigate(['/'])"
          />
          <div class="text-900 text-3xl font-medium mb-3">Register</div>
          <span *ngIf="step === 'request-otp'" class="text-600 font-medium"
            >Enter the mobile number associated with your account and request
            for OTP to verify you are the owner of the mobile number.</span
          >
          <span
            *ngIf="step === 'verify-otp-register'"
            class="text-600 font-medium"
            >Enter the OTP you received via mobile to verify you are the owner
            of the mobile and set your login password.
          </span>
          <p-messages [value]="messages"></p-messages>
        </div>

        <div class="w-full md:w-10 mx-auto">
          <ng-container *ngIf="step === 'request-otp'">
            <label for="email1" class="block text-900 text-xl font-medium mb-2"
              >Mobile No.</label
            >
            <div class="custom-inputgroup mb-3">
              <div>
                <p-dropdown
                  [options]="countryMobileCode"
                  [(ngModel)]="selectedCountryMobileCode"
                  optionLabel="name"
                >
                  <ng-template pTemplate="selectedItem">
                    <div
                      class="country-item country-item-value"
                      *ngIf="selectedCountryMobileCode"
                    >
                      <img
                        src="assets/demo/flags/flag_placeholder.png"
                        [class]="
                          'flag flag-' +
                          selectedCountryMobileCode.code.toLowerCase()
                        "
                      />
                      <div>{{ selectedCountryMobileCode.name }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-country pTemplate="item">
                    <div class="country-item">
                      <img
                        src="assets/demo/flags/flag_placeholder.png"
                        [class]="'flag flag-' + country.code.toLowerCase()"
                      />
                      <div>{{ country.name }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
              <div style="flex-basis: 100%">
                <input
                  type="text"
                  pInputText
                  [(ngModel)]="mobileNumber"
                  placeholder="121234567"
                  class="w-full"
                  style="padding: 1rem"
                />
              </div>
            </div>

            <button
              [disabled]="!isValidMobile"
              pButton
              pRipple
              label="Get OTP"
              (click)="requestOTP()"
              class="w-full p-3 text-xl"
            ></button>
          </ng-container>
          <ng-container *ngIf="step === 'verify-otp-register'">
            <label for="email1" class="block text-900 text-xl font-medium mb-2"
              >Mobile No.</label
            >
            <input
              pInputText
              [disabled]="true"
              id="email1"
              class="w-full mb-3"
              type="text"
              [(ngModel)]="internalMobile"
            />

            <ng-container [formGroup]="signUpForm">
              <label
                for="name1"
                class="block text-900 font-medium text-xl mb-2"
                >Name</label
              >
              <input
                pInputText
                id="name"
                class="w-full mb-3"
                type="text"
                formControlName="name"
              />

              <label
              for="nric1"
              class="block text-900 font-medium text-xl mb-2"
              >NRIC / Passport (example : 961213112233)</label
            >
            <input
              pInputText
              id="nric"
              class="w-full mb-3"
              type="text"
              formControlName="nric"
            />

            <label for="email" class="block text-900 font-medium text-xl mb-2"
            >Email</label
          >
            <input
              pInputText
              id="email"
              class="w-full mb-3"
              type="email"
              formControlName="email"
              required
            />

              <label
                for="password1"
                class="block text-900 font-medium text-xl mb-2"
                >OTP</label
              >
              <input
                pInputText
                id="otp"
                class="w-full mb-3"
                type="text"
                formControlName="otp"
              />

              <label
                for="password1"
                class="block text-900 font-medium text-xl mb-2"
                >Login Password</label
              >
              <p-password
                formControlName="password"
                class="password-no-feedback"
                [toggleMask]="true"
                [feedback]="false"
              >
              </p-password>
              <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
              </ul>

              <label
                for="password2"
                class="block text-900 font-medium text-xl mb-2"
                >Re-type Password</label
              >

              <p-password
                formControlName="confirmPassword"
                class="password-no-feedback"
                [toggleMask]="true"
                [feedback]="false"
              ></p-password>

              <button
                [disabled]="!signUpValid"
                pButton
                pRipple
                label="Sign-Up"
                (click)="signUp()"
                class="w-full p-3 text-xl mb-3"
              ></button>
              <button
                [disabled]="!isValidMobile"
                pButton
                pRipple
                label="Resend OTP"
                (click)="requestOTP()"
                class="w-full p-3 text-xl p-button-outlined"
              ></button>
            </ng-container>
          </ng-container>

          <div class="flex align-items-center justify-content-center mt-4">
            <a
              class="font-medium no-underline cursor-pointer"
              style="color: var(--primary-color)"
              (click)="router.navigate(['login'])"
              >Go to Login</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
