<div class="grid">
  <div
    class="col-12"
    style="display: flex; align-items: center; justify-content: space-between"
  >
    <label class="title">Manage Resolutions</label>
    <button
      pButton
      pRipple
      label="Create Resolution"
      class="p-button-raised p-button-primary mr-2 mb-2"
      (click)="createResolution()"
    ></button>
  </div>
  <div class="col-12">
    <dl>
      <dt>
        <b>Ordinary Resolution:</b> Carried if more than 50% of the shares voted
        FOR
      </dt>
      <dt>
        <b>Special Resolution:</b> Carried if more than 75% of the shares voted
        FOR
      </dt>
      <dt>
        <b>2 Tiers Resolution:</b> Carried if both Large shareholders and Others
        shareholders have more than 50% shares voted FOR in respective level
      </dt>
    </dl>
  </div>
  <div class="col-12">
    <p-table
      #dt1
      [value]="resolutions"
      dataKey="id"
      [rows]="10"
      [loading]="loading"
      [rowHover]="true"
      styleClass="p-datatable-gridlines"
      [paginator]="true"
      [globalFilterFields]="['title', 'type']"
      responsiveLayout="scroll"
    >
      <ng-template pTemplate="caption">
        <div class="flex justify-content-between flex-column sm:flex-row">
          <span class="p-input-icon-left mb-2">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              #filter
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              placeholder="Search Keyword"
              class="w-full"
            />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>
            <div class="flex justify-content-between align-items-center">
              Resolution Number
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Resolution Description
            </div>
          </th>

          <th>
            <div class="flex justify-content-between align-items-center">
              Type
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              CDS to Abstain
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Actions
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-resolution>
        <tr>
          <td style="min-width: 12rem">
            {{ resolution.index }}
          </td>
          <td style="min-width: 12rem">
            {{ resolution.title }}
          </td>

          <td style="min-width: 14rem">
            {{ resolution.type }}
          </td>
          <td style="min-width: 10rem">
            {{ abstainCdsNumber(resolution) }}
          </td>

          <td style="min-width: 12rem">
            <button
              pButton
              pRipple
              label="View"
              class="p-button-sm p-button-primary mr-2 mb-2"
              (click)="viewResolution(resolution)"
            ></button>
            <button
              pButton
              pRipple
              icon="pi pi-trash"
              class="p-button-sm p-button-rounded p-button-danger mr-2 mb-2"
              (click)="deleteResolutionConfirmation(resolution)"
            ></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No resolutions found.</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
          <td colspan="7">Loading event resolutions. Please wait.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
