<div class="grid">
  <div
    class="col-12"
    style="display: flex; align-items: center; justify-content: space-between"
  >
    <label class="title">Questions</label>
  </div>

  <div class="col-12">
    <p-table
      #dt1
      [value]="questions"
      dataKey="id"
      [rows]="10"
      [loading]="loading"
      [rowHover]="true"
      styleClass="p-datatable-gridlines"
      [paginator]="true"
      [globalFilterFields]="['title', 'type']"
      responsiveLayout="scroll"
    >
      <ng-template pTemplate="caption">
        <div class="flex justify-content-between flex-column sm:flex-row">
          <span class="p-input-icon-left mb-2">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              #filter
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              placeholder="Search Keyword"
              class="w-full"
            />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>
            <div class="flex justify-content-between align-items-center">
              No.
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Name
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Type
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Questions
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-question let-rowIndex="rowIndex">
        <tr>
          <td style="min-width: 8rem">
            {{ rowIndex + 1 }}<!-- +1 because index starts from 0 -->
          </td>
          <td style="min-width: 12rem">
            {{ question.data.name }}
          </td>
          <td style="min-width: 12rem">
            {{ getRoleList(question.data.roles) }}
          </td>
          <td style="min-width: 12rem">
            {{ question.data.question }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No questions found.</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
          <td colspan="7">Loading event questions. Please wait.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
