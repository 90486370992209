<div class="flex justify-content-between flex-column sm:flex-row mb-3">
  <h4 class="title">Event Info</h4>
  <button
    *ngIf="event.polling.status === PollingStatusEnum.NOT_STARTED"
    pButton
    pRipple
    label="Edit Event"
    class="p-button-raised p-button-primary mr-2"
    (click)="editEvent()"
  ></button>
  <button
    *ngIf="event.polling.status === PollingStatusEnum.START"
    pButton
    pRipple
    label="End Polling"
    class="p-button-raised p-button-danger mr-2"
    (click)="endPolling()"
  ></button>
  <button
    *ngIf="event.polling.status === PollingStatusEnum.END"
    pButton
    pRipple
    label="Publish Polling Result"
    class="p-button-raised p-button-danger mr-2"
    (click)="publishPollingResult()"
  ></button>
</div>

<div class="grid mb-3">
  <div class="col-6">
    <div><b>Name: </b>{{ event.name }}</div>
    <div><b>Description: </b>{{ event.description }}</div>
    <div><b>Date: </b>{{ event.startAt | date: "fullDate" }}</div>
    <div>
      <b>Time: </b>{{ event.startAt | date: "shortTime" }}-{{
        event.endAt | date: "shortTime"
      }}
    </div>
    <!-- <div><b>Calculate Result by: </b>{{ event.setting.calculateResultBy }}</div>
    <div><b>Question Feature: </b>{{ event.setting.enableQuestion }}</div> -->
  </div>
  <div class="col-6">
    <div
      *ngIf="
        event.polling.status === PollingStatusEnum.END ||
        event.polling.status === PollingStatusEnum.PUBLISH
      "
    >
      <b>Polling Closed At: </b>{{ event.polling.endAt | date: "medium" }}
    </div>
    <!-- <div><b>Proxy Settings</b></div>
    <div><b>Registration: </b>{{ event.setting.enableProxyRegstr }}</div>
    <div>
      <b>Registration Cut Off Period: </b
      >{{ event.setting.proxyRegstrCutOffTime }}
    </div>
    <div><b>Limit: </b>{{ event.setting.noOfProxy }}</div> -->
  </div>
</div>

<div
  *ngIf="event.polling.status === PollingStatusEnum.NOT_STARTED"
  class="process-cards grid"
>
  <div class="col-12">
    <p-card>
      <div class="flex align-items-center justify-content-between">
        <div class="flex align-items-center">
          <p-badge
            [value]="1"
            size="xlarge"
            severity="warning"
            styleClass="mr-3"
          ></p-badge>
          <div class="mr-2">
            <label style="font-size: larger"
              ><b>Setup Directors and Shareholders</b></label
            >
            <div>
              Make sure you import the up-to-date shareholder list and assign
              the chairman before adding the proxy for the shareholders
            </div>
          </div>
        </div>

        <div
          class="action-text cursor"
          (click)="triggerTab(EventDashboardTabEnum.SHAREHOLDER)"
        >
          Import Shareholders
        </div>
      </div>
    </p-card>
  </div>

  <div class="col-12">
    <p-card>
      <div class="flex align-items-center justify-content-between">
        <div class="flex align-items-center">
          <p-badge
            [value]="2"
            size="xlarge"
            severity="warning"
            styleClass="mr-3"
          ></p-badge>
          <div class="mr-2">
            <label style="font-size: larger"><b>Setup Resolutions</b></label>
            <div>
              Make sure you key in the event resolutions and assign the right
              resolution type before you start to do pre-vote to ensure the
              polling is calculated correctly.
            </div>
          </div>
        </div>

        <div
          class="action-text cursor"
          (click)="triggerTab(EventDashboardTabEnum.RESOLUTION)"
        >
          Resolution Settings
        </div>
      </div>
    </p-card>
  </div>

  <div class="col-12">
    <p-card>
      <div class="flex align-items-center justify-content-between">
        <div class="flex align-items-center">
          <p-badge
            [value]="3"
            size="xlarge"
            severity="warning"
            styleClass="mr-3"
          ></p-badge>
          <div class="mr-2">
            <label style="font-size: larger"
              ><b>Add Proxies and Pre-Vote</b></label
            >
            <div>
              Make sure you add proxies based on the proxy form and remember to
              upload the proxy form for future references.
            </div>
          </div>
        </div>
        <div
          class="action-text cursor"
          (click)="triggerTab(EventDashboardTabEnum.PROXY)"
        >
          Add Proxies
        </div>
      </div>
    </p-card>
  </div>

  <div class="col-12">
    <p-card>
      <div class="flex align-items-center justify-content-between">
        <div class="flex align-items-center">
          <p-badge
            [value]="4"
            size="xlarge"
            severity="warning"
            styleClass="mr-3"
          ></p-badge>
          <div class="mr-2">
            <label style="font-size: larger"
              ><b>Generate Proxy Consolidated Report</b></label
            >
            <div>Generate and export the proxies pre-vote result</div>
          </div>
        </div>
        <div
          class="action-text cursor"
          (click)="triggerTab(EventDashboardTabEnum.PROXY_VOTING)"
        >
          Generate Proxy Consolidated Report
        </div>
      </div>
    </p-card>
  </div>

  <div class="col-12">
    <p-card>
      <div class="flex align-items-center justify-content-between">
        <div class="flex align-items-center">
          <p-badge
            [value]="5"
            size="xlarge"
            severity="warning"
            styleClass="mr-3"
          ></p-badge>
          <div class="mr-2">
            <label style="font-size: larger"><b>Add Invitees</b></label>
            <div>Make sure you add the invitess before the event started</div>
          </div>
        </div>
        <div
          class="action-text cursor"
          (click)="triggerTab(EventDashboardTabEnum.INVITEE)"
        >
          Add Invitees
        </div>
      </div>
    </p-card>
  </div>

  <div class="col-12">
    <p-card>
      <div class="flex align-items-center justify-content-between">
        <div class="flex align-items-center">
          <p-badge
            [value]="6"
            size="xlarge"
            severity="warning"
            styleClass="mr-3"
          ></p-badge>
          <div class="mr-2">
            <label style="font-size: larger"><b>Polling Process</b></label>
            <div>
              Make sure the minimum amount of attendants is achieved before the
              polling start. Make sure all the major shareholder is voted before
              ending the polling process.
            </div>
          </div>
        </div>

        <div class="action-text" style="min-width: 14rem">
          <button
            pButton
            pRipple
            label="Start Polling"
            class="p-button-raised p-button-primary mr-2"
            (click)="startPolling()"
          ></button>
        </div>
      </div>
    </p-card>
  </div>
</div>

<div *ngIf="event.polling.startAt" class="result-display grid">
  <div class="col-12">
    <p-table
      #dt1
      [value]="votingResult"
      dataKey="id"
      [rows]="10"
      [rowHover]="true"
      styleClass="p-datatable-gridlines"
      [paginator]="true"
      responsiveLayout="scroll"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>
            <div class="flex justify-content-between align-items-center">
              No
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Resolution Type
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Resolution Description
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Level
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              For % (Units)
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Against % (Units)
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Abstain Units
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Result
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-result>
        <tr>
          <td style="min-width: 5rem">
            {{ result.index }}
          </td>
          <td style="min-width: 10rem">
            {{ result.type }}
          </td>

          <td style="min-width: 20rem">
            {{ result.title }}
          </td>
          <td style="min-width: 10rem">
            <ng-container *ngIf="result.type === 'TWO_TIER'">
              <div class="large-tier">Large</div>
              <div>Other</div>
            </ng-container>
          </td>
          <td style="min-width: 10rem">
            <ng-container *ngIf="result.type === 'TWO_TIER'">
              <div class="large-tier">
                {{ result.for.largePercentage | number: "1.4-4" }}% ({{
                  result.for.largeUnit | number: "1.0-0"
                }})
              </div>
              <div>
                {{ result.for.smallPercentage | number: "1.4-4" }}% ({{
                  result.for.smallUnit | number: "1.0-0"
                }})
              </div>
            </ng-container>
            <ng-container *ngIf="result.type !== 'TWO_TIER'">
              {{ result.for.percentage | number: "1.4-4" }}% ({{
                result.for.unit | number: "1.0-0"
              }})
            </ng-container>
          </td>
          <td style="min-width: 10rem">
            <ng-container *ngIf="result.type === 'TWO_TIER'">
              <div class="large-tier">
                {{ result.against.largePercentage | number: "1.4-4" }}% ({{
                  result.against.largeUnit | number: "1.0-0"
                }})
              </div>
              <div>
                {{ result.against.smallPercentage | number: "1.4-4" }}% ({{
                  result.against.smallUnit | number: "1.0-0"
                }})
              </div>
            </ng-container>
            <ng-container *ngIf="result.type !== 'TWO_TIER'">
              {{ result.against.percentage | number: "1.4-4" }}% ({{
                result.against.unit | number: "1.0-0"
              }})
            </ng-container>
          </td>
          <td style="min-width: 10rem">
            <ng-container *ngIf="result.type === 'TWO_TIER'">
              <div class="large-tier">
                {{ result.abstain.largeUnit | number: "1.0-0" }}
              </div>
              <div>
                {{ result.abstain.smallUnit | number: "1.0-0" }}
              </div>
            </ng-container>
            <ng-container *ngIf="result.type !== 'TWO_TIER'">
              {{ result.abstain.unit | number: "1.0-0" }}
            </ng-container>
          </td>
          <td style="min-width: 10rem">
            {{ result.result }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No resolutions found.</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
          <td colspan="7">Loading event resolutions. Please wait.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
