<div class="grid">
  <div class="col-6">
    <label class="title">Representative List</label>
  </div>
  <div class="col-6" style="display: flex; justify-content: end">
    <button
      pButton
      pRipple
      label="Add Corporate"
      class="p-button-primary mr-2"
      (click)="createCorporate()"
    ></button>
  </div>

  <div class="col-12">
    <p-table
      #dt1
      [value]="corporates"
      dataKey="id"
      [rows]="10"
      [rowHover]="true"
      styleClass="p-datatable-gridlines"
      [paginator]="true"
      [globalFilterFields]="['name']"
      responsiveLayout="scroll"
    >
      <ng-template pTemplate="caption">
        <div class="flex justify-content-between flex-column sm:flex-row">
          <span class="p-input-icon-left mb-2">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              #filter
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              placeholder="Search Keyword"
              class="w-full"
            />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pFrozenColumn>
            <div class="flex justify-content-between align-items-center">
              Name
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Mobile
            </div>
          </th>
          <th pFrozenColumn alignFrozen="right">
            <div class="flex justify-content-between align-items-center">
              Actions
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-corporate>
        <tr>
          <td style="min-width: 6rem" pFrozenColumn>
            {{ corporate.name }}
          </td>
          <td style="min-width: 15rem">{{ corporate.mobile }}</td>
          <td pFrozenColumn alignFrozen="right">
            <button
              pButton
              pRipple
              label="View"
              class="p-button-primary mr-2"
              (click)="editCorporate(corporate)"
            ></button>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No corporates.</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
          <td colspan="7">Loading corporates list. Please wait.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
