import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Socket, SocketIoConfig } from 'ngx-socket-io';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@Injectable({
  providedIn: 'root',
})
export class SocketAuthService extends Socket {
  constructor() {
    const socketIoConfig: SocketIoConfig = {
      url: environment.API_URL,
      options: {
        // path: '/api/socket.io',
        // extraHeaders: {
        //   Authorization: tokenGetter(),
        // },
      },
    };
    // if (environment.API_URL === 'https://dev.vpoll.com.my/api') {
    //   socketIoConfig.options.path = '/api/socket.io';
    // }
    console.log(socketIoConfig);
    super(socketIoConfig);
    this.refreshToken();
  }

  refreshToken() {
    this.ioSocket['auth'] = { token: tokenGetter() };
  }
}
