<div class="grid">
    <div class="col-12">
        <div class="card docs">
            <h3>Current Version</h3>
            <p>Angular 13 and PrimeNG 13</p>

            <h4>Getting Started</h4>
            <p>Sakai is an application template for Angular and is distributed as a CLI project. In case CLI is not installed already, use
                the command below to set it up.
            </p>

<app-code  ngPreserveWhitespaces ngNonBindable lang="markup">
npm install -g @angular/cli
</app-code>

            <p>Once CLI is ready in your system, clone the github repository, cd to the directory,
            install the libraries from npm and then execute "ng serve" to run the application in your local environment.</p>

<app-code ngPreserveWhitespaces ngNonBindable lang="markup">
git clone https://github.com/primefaces/sakai-ng.git
cd sakai-ng
npm install
ng serve
</app-code>

            <p>The application should run at <i>http://localhost:4200/</i>, you may now start with the development of your application.</p>

            <h5>Important CLI Commands</h5>
            <p>Following commands are derived from CLI.</p>
<app-code ngPreserveWhitespaces ngNonBindable lang="markup">
Run 'ng serve' for a dev server. Navigate to `http://localhost:4200/`. The app will automatically reload if you change any of the source files.

Run 'ng generate component component-name' to generate a new component. You can also use `ng generate directive/pipe/service/class/module`.

Run 'ng build' to build the project. The build artifacts will be stored in the `dist/` directory. Use the `-prod` flag for a production build.

Run 'ng test' to execute the unit tests via [Karma](https://karma-runner.github.io).

Run 'ng e2e' to execute the end-to-end tests via [Protractor](http://www.protractortest.org/).

Run 'ng help' for more options.
</app-code>

            <h5>Dependencies</h5>
            <p>Sakai has no direct dependency other than PrimeNG. More information about dependencies of PrimeNG templates is available at <a href="https://www.primefaces.org/why-primeng-templates/">Why PrimeNG Templates</a> article.</p>

            <h4>Structure</h4>
            <p>Sakai consists of 2 main parts; the application layout, layout resources. <i>app.component.html</i> inside app folder is the html template for the base layout,
                required resources for the layout are placed inside the <i>src/assets/layout</i> folder and similarly theme resources are inside <i>src/assets/theme</i> folder.
            </p>

            <h5>Application Template</h5>
            <p>Main layout is the html template of the app.main.component.ts, it is divided into a couple of sections such as topbar, sidebar, right panel and footer. Here is the code for
                the main template. The component class app.main.component.ts implements the logic such as opening menus and managing layout modes.
            </p>

            <h5>Menu</h5>
            <p>Menu is a separate component defined in app.menu.component.ts file based on PrimeNG MenuModel API. In order to define the menuitems,
            navigate to this file and define your own model as a nested structure. Here is the menu component from the sample application.</p>

            <h5>Theme</h5>
            <p>Sakai layout derives the color scheme from the PrimeNG theme being used so that it can be combined any theme such as PrimeOne, Material, Bootstrap and others with dark mode alternatives.</p>

            <h5>SASS Variables</h5>
            <p>In case you'd like to customize the layout variables, open <i>_variables.scss</i> file under src/assets/sass/layout folder. Saving the changes
                will be reflected instantly at your browser.</p>
        </div>
    </div>
</div>