<div class="grid">
  <div class="col-6">
    <label class="title">Proxy List</label>
  </div>
  <div class="col-6" style="display: flex; justify-content: end">
    <button
      pButton
      pRipple
      label="Add Proxy"
      class="p-button-primary mr-2"
      (click)="createProxy()"
    ></button>
  </div>

  <div class="col-12">
    <p-table
      #dt1
      [value]="proxies"
      dataKey="id"
      [rows]="10"
      [rowHover]="true"
      styleClass="p-datatable-gridlines"
      [paginator]="true"
      [globalFilterFields]="['name', 'cds', 'identityNumber']"
      responsiveLayout="scroll"
    >
      <ng-template pTemplate="caption">
        <div class="flex justify-content-between flex-column sm:flex-row">
          <span class="p-input-icon-left mb-2">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              #filter
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              placeholder="Search Keyword"
              class="w-full"
            />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pFrozenColumn>
            <div class="flex justify-content-between align-items-center">
              Proxy Name
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Proxy NRIC
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Shareholder NRIC
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Shareholder CDS
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Number of Shares Allocated
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Voting Privilege
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Is Pre-Vote
            </div>
          </th>
          <th pFrozenColumn alignFrozen="right">
            <div class="flex justify-content-between align-items-center">
              Actions
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-proxy>
        <tr>
          <td style="min-width: 6rem" pFrozenColumn>
            {{ proxy.name }}
          </td>
          <td style="min-width: 15rem">{{ proxy.identityNumber }}</td>
          <td style="min-width: 8rem">
            {{ proxy.shareholderId.identityNumber }}
          </td>
          <td style="min-width: 8rem">
            {{ proxy.shareholderId.cds }}
          </td>
          <td style="min-width: 8rem">
            {{ proxy.allocatedShares | number: "1.0-0" }}
          </td>
          <td style="min-width: 8rem"></td>
          <td>
            {{ proxy.voteSetting.isPreVote ? "Yes" : "No" }}
          </td>
          <td pFrozenColumn alignFrozen="right">
            <button
              pButton
              pRipple
              label="View"
              class="p-button-primary mr-2"
              (click)="editProxy(proxy)"
            ></button>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No proxies.</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
          <td colspan="7">Loading proxies list. Please wait.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
